import { userInfo } from "@/api/index";
import user from "./user";
import security from "./security";
import social from "./social";
import message from "./message";
export default {
  components: {
    user,
    security,
    social,
    message
  },
  name: "ownspace_index",
  data() {
    return {
      activeName: "基本信息",
      currMenu: "基本信息"
    };
  },
  methods: {
    init() {},
    changeMenu(v) {
      this.currMenu = v;
    },
    updateUserInfo() {
      // 更新用户信息
      userInfo().then(res => {
        if (res.success) {
          // 避免超过大小限制
          delete res.result.permissions;
          delete res.result.roles;
          if (this.getStore("saveLogin")) {
            // 保存7天
            Cookies.set("userInfo", JSON.stringify(res.result), {
              expires: 7
            });
          } else {
            Cookies.set("userInfo", JSON.stringify(res.result));
          }
          this.setStore("userInfo", res.result);
        }
      });
    }
  },
  mounted() {
    let type = this.$route.query.type;
    if (type == "social") {
      this.activeName = "第三方账号绑定";
      this.currMenu = "第三方账号绑定";
    }
    this.init();
  }
};